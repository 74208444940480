import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2015 - 2020", 
      title: "Ingeniero Administrador en Sistemas", 
      place: "Universidad Autónoma de Nuevo León", 
      desc: "Me gradué como Ingeniero Administrador en Sistemas de la Universidad Autónoma de Nuevo León, " +
      "donde adquirí una sólida formación técnica en el diseño, desarrollo y gestión de soluciones " +
      "tecnológicas. A lo largo de mi carrera, me especialicé en la integración de sistemas, administración " +
      "de bases de datos y optimización de procesos empresariales, lo que me permitió desarrollar una " +
      "visión integral sobre cómo la tecnología puede transformar y mejorar los procesos organizacionales. " +
      "Durante mi formación, participé en proyectos prácticos que reforzaron mis habilidades en programación, " +
      "análisis de datos y gestión de infraestructuras tecnológicas."
    },
    {
      yearRange: "2021", 
      title: "Big Data y Estrategia de Negocios", 
      place: "Universidad del Valle de México", 
      desc: "Completé el Diplomado en Big Data y Estrategia de Negocios en la Universidad del Valle de México, " + 
      "un programa intensivo de seis meses que me permitió profundizar en el análisis de grandes volúmenes " +
      "de datos y su aplicación estratégica en el entorno empresarial. Durante el diplomado, adquirí " +
      "habilidades clave en la gestión y análisis de datos, así como en la toma de decisiones informadas " +
      "para impulsar la competitividad y eficiencia organizacional. El enfoque práctico del programa me " +
      "permitió comprender cómo las tecnologías de Big Data pueden transformar la forma en que las empresas " +
      "desarrollan estrategias de negocios y toman decisiones basadas en datos"},
    {
      yearRange: "2015 - 2016", 
      title: "Tecnico Desarrollador de Software", 
      place: "Universidad Autónoma de Nuevo León", 
      desc: "Realicé la carrera tecnica 'Técnico en Desarrollo de Software' en la Universidad Autónoma " +
      "de Nuevo León, paralelamente a mi formación en Ingeniería Administrador en Sistemas. Este programa " +
      "técnico me proporcionó una base sólida en programación, diseño de aplicaciones y desarrollo de " +
      "soluciones informáticas, complementando mi carrera universitaria con habilidades prácticas en " +
      "lenguajes de programación y metodologías de desarrollo ágil. A través de este diplomado, " +
      "fortalecí mi capacidad para construir soluciones tecnológicas eficientes y de alta calidad, " +
      "lo que me permitió abordar con mayor solidez proyectos complejos durante mi trayectoria profesional."},
  ];

  const experienceDetails = [
    { yearRange: "2021 - Actual", 
      title: "Salesforce Developer", 
      place: "Banregio",
      desc: "Formo parte del equipo de desarrollo CRM, especializado en la implementación y personalización " +
      "de soluciones sobre plataformas como Salesforce Sales Cloud, Salesforce Service Cloud y " +
      "Salesforce Field Service. Mi función principal es diseñar y desarrollar soluciones a medida, " +
      "colaborando estrechamente con los equipos de Banregio y Hey Banco para mejorar procesos comerciales, " +
      "optimizar la gestión del servicio al cliente y garantizar una experiencia de usuario más eficiente " +
      "y satisfactoria.",
    },
    { yearRange: "2023 - 2024", 
      title: "Instructor Salesforce", 
      place: "Emtech",
      desc: "Formé parte del equipo docente en Emtech Institute, donde mi principal responsabilidad fue " +
      "ofrecer tutorías grupales enfocadas en reforzar los contenidos del módulo y facilitar prácticas " +
      "para asegurar la aplicación efectiva de lo aprendido. Además, brindé sesiones individuales de apoyo " +
      "a los estudiantes, con el objetivo de optimizar su preparación y garantizar que pudieran obtener " +
      "su certificación en Salesforce. Mi enfoque estuvo en proporcionar una enseñanza personalizada " +
      "y asegurarme de que cada alumno alcanzara el dominio necesario para enfrentar con éxito los " +
      "desafíos de la plataforma."
    },
    { yearRange: "2019 - 2021", 
      title: "Salesforce Developer",
      place: "Clínicas del Azúcar",
      desc: "En Clínicas del Azúcar, fui responsable de administrar y optimizar el ecosistema completo " +
      "de Salesforce, incluyendo Sales Cloud, Service Cloud y Marketing Cloud. Mi rol abarcó el desarrollo " +
      "e implementación de nuevas funcionalidades, adaptando la plataforma a las necesidades cambiantes " +
      "de la empresa. Trabajé de cerca con los equipos internos para garantizar una integración eficiente " +
      "y el máximo aprovechamiento de las herramientas de Salesforce, con el fin de mejorar la gestión " +
      "de clientes y los procesos operativos.",
    },
    { yearRange: "2017 - 2019", 
      title: "Soporte", 
      place: "Delaware Software",
      desc: "Brindé soporte técnico al sistema de información utilizado en el Centro Universitario Contra " +
      "el Cáncer, ubicado en el Hospital Universitario de la Universidad Autónoma de Nuevo León. " +
      "Mi principal responsabilidad fue garantizar el funcionamiento óptimo de las soluciones tecnológicas, " +
      "resolver incidencias y asistir en la resolución de problemas técnicos, asegurando que los usuarios " +
      "pudieran contar con un sistema eficiente para apoyar las operaciones del centro de salud.",
    },
  ];

  const skills = [
    { name: "Salesforce Certified Associate", id: "ID: 3277718"},
    { name: "Salesforce Certified IA Associate", id: "ID: 3694378"},
    { name: "Salesforce Certified Administrator (SCA)", id: "ID: 22791562"},
    { name: "Salesforce Certified Advanced Administrator (SCAA)", id: "ID: 2401843"},
    { name: "Salesforce Certified Platform App Builder", id: "ID: 23009174"},
    { name: "Salesforce Certified Platform Developer I", id: "ID: 22926275"},
    { name: "Salesforce Certified Service Cloud Consultant", id: "ID: 5148693"},
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2 text-white">CV</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Un resumen de mi CV
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Educación</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Experiencia</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">Certificaciones</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end text-muted">{skill.id}</span>
                </p>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href=""
            
          >
            Descargar CV
          </a>
        </p>
      </div>
    </section>
  );
};
//download
export default Resume;
