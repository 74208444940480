import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2 text-white">Sobre mi</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Saber más de mi
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hola, soy {" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Josue Mendez
              </span>
            </h2>
            <p className="text-5">
              Soy Ingeniero Administrador de Sistemas egresado de la Facultad de Ingeniería Mecánica 
              y Eléctrica de la UANL. 
              Con más de 6 años de experiencia, me he especializado en el desarrollo y 
              la optimización de soluciones en Salesforce, siendo un referente en la 
              implementación de Sales Cloud, Service Cloud y Field Service.
            </p>
            <p className="text-5">
              A lo largo de mi carrera, he adquirido 7 certificaciones en Salesforce, consolidando mi 
              conocimiento y experiencia en la plataforma líder de CRM. Actualmente, 
              me desempeño como Desarrollador Salesforce en una institución financiera de 
              alto nivel, donde aplico mis habilidades para crear soluciones innovadoras y 
              transformar procesos clave dentro de la organización.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle text-white">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  6
                </span>
              </div>
              <h3 className="text-5 wow rubberBand" data-wow-delay="2s">
                años de Experiencia en <span className="fw-700">Salesforce</span>.
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Nombre:</p>
            <p className="text-4 text-dark fw-600 mb-0">Josue Mendez</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">LinkedIn:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" target="_blank" href="https://www.linkedin.com/in/josuemdzm">
                Josue Mendez
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-2 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Trailblazer Id:</p>
            <p className="text-4 text-dark fw-600 mb-0">
            <a className="link-dark" target="_blank" href="https://www.salesforce.com/trailblazer/josuemdzm">
                josuemdzm
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-4 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">De:</p>
            <p className="text-4 text-dark fw-600 mb-0">Monterrey, Nuevo León, México.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
